import React, { useState, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
import '../../../ALL Css/ALL Dashboard CSS/Dashboard.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const NotFound = () => <div className="not-found">404 - Page Not Found</div>;

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [componentExists, setComponentExists] = useState(true);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
        const response = await axios.post(`${API_BASE_URL}/api/menus`, {
          SUCode: storedUserDetails.SUCode,
          EmailID: storedUserDetails.EmailID,
        });
        setMenuData(response.data);
      } catch (error) {
        setError('Error fetching menu: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchMenuData();
  }, []);

  const handleGridClick = (componentName) => {
    setActiveComponent(componentName);
    setComponentExists(true);
  };

  const loadComponent = (frmName) => {
    try {
      const Component = lazy(() => 
        import(`../../AdminPart/AdminDetails/${frmName}.js`).catch(() => {
          setComponentExists(false);
          return { default: NotFound };
        })
      );
      return <Suspense fallback={<div>Loading...</div>}><Component /></Suspense>;
    } catch (error) {
      console.error(`Component not found for FrmName: ${frmName}`, error);
      return <NotFound />;
    }
  };

  const getIconForMenu = (menuName) => {
    const menuIcons = {
      'New Enquiry':'https://cdn-icons-png.freepik.com/256/15339/15339160.png?ga=GA1.1.1437743925.1738994281&semt=ais_hybrid',

      'Manage Enquiry':'https://cdn-icons-png.freepik.com/256/3263/3263153.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',
      
      'Pending Fees': 'https://cdn-icons-png.freepik.com/256/8675/8675278.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Paid Fees':'https://cdn-icons-png.freepik.com/256/12129/12129181.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'User Manager': 'https://img.freepik.com/free-vector/user-with-pie-chart_78370-7032.jpg?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Role Manager': 'https://www.shutterstock.com/image-vector/businessman-cog-wheels-icon-vector-260nw-1828642166.jpg',

      'Center Asignment':'https://cdn-icons-png.freepik.com/256/18465/18465469.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Special Coupon':'https://cdn-icons-png.freepik.com/256/2331/2331728.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'New Enrollment': 'https://cdn-icons-png.freepik.com/256/18716/18716009.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Manage Students': 'https://cdn-icons-png.freepik.com/256/3317/3317713.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Board':'https://cdn-icons-png.freepik.com/256/13854/13854840.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Class':'https://cdn-icons-png.freepik.com/256/3104/3104438.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Subject':'https://cdn-icons-png.freepik.com/256/7292/7292994.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Center':'https://cdn-icons-png.freepik.com/256/9200/9200893.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Batch':'https://cdn-icons-png.freepik.com/256/9805/9805564.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Teacher Alotment':'https://cdn-icons-png.freepik.com/256/8947/8947786.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Batch Fees':'https://cdn-icons-png.freepik.com/256/6982/6982787.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Batch Content': 'https://cdn-icons-png.freepik.com/256/9881/9881260.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Student Alotment':'https://cdn-icons-png.freepik.com/256/15325/15325579.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Time Alotment':'https://cdn-icons-png.freepik.com/256/3562/3562769.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Batch Calender':'https://cdn-icons-png.freepik.com/256/3391/3391531.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'LMS Video' : 'https://cdn-icons-png.freepik.com/256/10124/10124688.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Manage Teacher':'https://cdn-icons-png.freepik.com/256/6543/6543786.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Teacher Calender':'https://cdn-icons-png.freepik.com/256/2986/2986495.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Payroll':'https://cdn-icons-png.freepik.com/256/18508/18508150.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Teacher Feedback':'https://cdn-icons-png.freepik.com/256/8163/8163539.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Leave Management System':'https://cdn-icons-png.freepik.com/256/14641/14641538.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Recruitment':'https://cdn-icons-png.freepik.com/256/12343/12343151.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'LMS`':'https://cdn-icons-png.freepik.com/256/1719/1719495.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Create New Exam':'https://cdn-icons-png.freepik.com/256/12959/12959004.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Exam Management System':'https://cdn-icons-png.freepik.com/256/17318/17318329.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Update Student Performance':'https://cdn-icons-png.freepik.com/256/16135/16135384.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Backdated Marks Entry':'https://cdn-icons-png.freepik.com/256/5055/5055436.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Inventory Management': 'https://cdn-icons-png.freepik.com/256/18293/18293727.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'Demo Class Management System':'https://cdn-icons-png.freepik.com/256/10868/10868504.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',

      'One Online Class Every Month':'https://cdn-icons-png.freepik.com/256/4140/4140142.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid',
    };
    return menuIcons[menuName] || 'https://cdn-icons-png.freepik.com/256/856/856114.png?ga=GA1.1.1912018713.1729572942&semt=ais_hybrid';
  };


  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="Dashboard-Container">
      {!activeComponent && (
        <div className="Dashboard-grid">
          {menuData.length > 0 ? (
            menuData.map((menu) => (
              menu.FrmName && (
                <div key={menu.MenuCode} className="Grid" onClick={() => handleGridClick(menu.FrmName)}>
                  <img 
                    src={`${getIconForMenu(menu.MenuName)}`} 
                    alt={menu.MenuName} 
                    className="Image" 
                  />
                  <h4 className='Grid-heading'>{menu.MenuName}</h4>
                  <p className='Grid-paragraph'>Manage {menu.MenuName}.</p>
                  {menu.SubMenus && menu.SubMenus.length > 0 && (
                    <div className="Submenu-list">
                      {menu.SubMenus.map((submenu) => (
                        <div key={submenu.MenuCode} className="Submenu-item">
                          <img 
                            src={`../../assets/icons/${(submenu.MenuName)}`} 
                            alt={submenu.MenuName} 
                            className="Submenu-Image" 
                          />
                          <h5>{submenu.MenuName}</h5>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )
            ))
          ) : (
            <div>No menus available</div>
          )}
        </div>
      )}

      {activeComponent && (componentExists ? loadComponent(activeComponent) : <NotFound />)}
    </div>
  );
};

export default Dashboard;
