import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"; 
import '../../../ALL Css/ALL Dashboard CSS/SubjectDetails.css';
import ChapterDetails from './ChapterDetails';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const SubjectDetails = ({ suCode, boardCode, classCode, onBack }) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');
  const [selectedSubject, setSelectedSubject] = useState(null);
  const navigate = useNavigate();
  


  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/subject-lms`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            suCode: suCode,
            boardCode: boardCode,
            classCode: classCode,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const uniqueSubjects = data.reduce((acc, curr) => {
          const existing = acc.find(subject => subject.SubjectName === curr.SubjectName);
          if (existing) {
            existing.details.push(curr);
          } else {
            acc.push({ SubjectName: curr.SubjectName, details: [curr] });
          }
          return acc;
        }, []);
        
        setSubjects(uniqueSubjects);
      } catch (error) {
        console.error('Failed to fetch subjects:', error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [suCode, boardCode, classCode]);

  const handleSubjectClick = (subjectCode, subjectName) => {
     const newBreadcrumb = `LMS > ${boardCode}> ${classCode} > ${subjectCode}`; 
    
    setBreadcrumb(newBreadcrumb);
    sessionStorage.setItem('breadcrumb', newBreadcrumb);
    sessionStorage.setItem('boardCode', boardCode);
    sessionStorage.setItem('classCode', classCode);
    sessionStorage.setItem('subjectCode', subjectCode);
    navigate(`/chapterdetails/${suCode}/${boardCode}/${classCode}/${subjectCode}`);
    setSelectedSubject({ subjectCode, subjectName });
  };

  const handleBackToClassDetails = () => {
    setSelectedSubject(null);
    
    const newBreadcrumb = `LMS > ${boardCode} > ${classCode}`;
    setBreadcrumb(newBreadcrumb);
    sessionStorage.setItem('breadcrumb', newBreadcrumb);

    sessionStorage.removeItem('subjectCode'); 
  };

  return (

      <div className="subject-details">
        <div className="breadcrumb">
          <p style={{ display: 'none' }}>{breadcrumb}</p>
        </div>
        {!selectedSubject ? (
        <>
        <div onClick={onBack}  className='Class-back-button-container'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-lg m-2 cursor-pointer" role="button" aria-hidden="true"><path fill="var(--ci-primary-color, currentColor)" d="M256,496A240,240,0,0,1,86.294,86.294,240,240,0,0,1,425.706,425.706,238.43,238.43,0,0,1,256,496Zm0-448C141.309,48,48,141.309,48,256s93.309,208,208,208,208-93.309,208-208S370.691,48,256,48Z" class="ci-primary"></path><polygon fill="var(--ci-primary-color, currentColor)" points="284.687 379.313 161.373 256 284.687 132.687 307.313 155.313 206.628 256 307.313 356.687 284.687 379.313" class="ci-primary"></polygon></svg></div>
          
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {subjects.map(subject => (
            <div key={subject.SubjectName} className="subject-item">
              <button
                onClick={() => handleSubjectClick(subject.details[0].SubjectCode, subject.SubjectName)}
                className="subject-button"
              >
                <span>{subject.SubjectName}</span>
              </button>
            </div>
          ))}
        </>
      ) : (
        <ChapterDetails 
          boardCode={boardCode} 
          classCode={classCode} 
          subjectCode={selectedSubject.subjectCode} 
          onBack={handleBackToClassDetails} 
        />
      )}
      </div>
      

  );
};

export default SubjectDetails;
