import React from 'react';
import '../../ALL Css/LoginSelect.css';
import { useNavigate, Link } from 'react-router-dom';

const LoginSelect = () => {
  const navigate = useNavigate();

  const handleAdminClick = () => {
    navigate('/FunscholarAdmin');
  };

  return (
    <div className='Whole-Content'>

      <div className='Heading'>
        <h4>Welcome back to LearningClass!</h4>
        <p>Choose an account type to proceed to sign in</p>
      </div>
      <div className="role-selection">

        {/* Administrator Account */}
        <div
          className="button-container"
          style={{ background: 'rgb(237, 103, 46)' }}
          onClick={handleAdminClick}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/adminButtonImg.png"
            alt="Administrator Account"
            style={{ width: '125px' }} />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Administrator Account</div>
              <div className="button-label2">I'm an Admin / IT specialist</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>


        {/* Educator Account */}
        <div
          className="button-container"
          style={{ background: 'rgb(242, 164, 80)' }}
          onClick={() => window.location.href = 'http://teacher.funscholar.com'}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/teacherButtonImg.png"
            alt="Educator Account"
            style={{ width: '125px' }}
          />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Educator Account</div>
              <div className="button-label2">I'm an educator</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div>



        {/* Student Account */}
        {/* <div
          className="button-container"
          style={{ background: 'rgb(246, 197, 98)' }}
          onClick={() => navigate('/studentlogin')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/studentButtonImg.png"
            alt="Student Account"
            style={{ width: '125px' }} />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Student Account</div>
              <div className="button-label2">I'm a student</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div> */}



        {/* Institute Account */}
        {/* <div
          className="button-container"
          style={{ background: 'rgb(240, 195, 90)' }}
          onClick={() => navigate('/instritutelogin')}
        >
          <img
            src="https://bc-website-image-repo.s3.ap-south-1.amazonaws.com/portal/principalButtonImg.png"
            alt="Institute Account"
            style={{ width: '125px' }} />
          <div className="button-right-block">
            <div>
              <div className="button-label1">Institute Account</div>
              <div className="button-label2">I'm an Institute</div>
            </div>
            <svg className="MuiSvgIcon-root" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
            </svg>
          </div>
        </div> */}
        
      </div>

      <div className="jss10">
        Don't have an account?{' '}
        <a className="jss11" href="/registrationform" target="_blank" rel="noopener noreferrer">
          Sign up now
        </a>

        <section className="LoginRoleSelect_footerSection__1tyI5">
          By signing in you agree to our{' '}
          <a href="https://learningclass.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          ,{' '}
          <a href="https://learningclass.com/terms-of-service" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
          , and{' '}
          <Link to="#" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </Link>
          . ©FunScaler. All Rights Reserved.
        </section>
      </div>
    </div>
  );
};

export default LoginSelect;
