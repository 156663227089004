/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../ALL Css/ALL Dashboard CSS/ChapterDetails.css';
import { useParams, useNavigate } from "react-router-dom";
import AdminHome from '../AdminHome';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ChapterDetails = () => {
  const { suCode, boardCode, classCode, subjectCode } = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem('user'));
  const userEmail = userData?.EmailID;
  const [breadcrumb, setBreadcrumb] = useState('');
  const [chapters, setChapters] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newChapterName, setNewChapterName] = useState('');
  const [newTopicName, setNewTopicName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isAddTopicModalOpen, setIsAddTopicModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedChapters, setExpandedChapters] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editChapterName, setEditChapterName] = useState('');
  const [editChapterCode, setEditChapterCode] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [selectedTopicCode, setSelectedTopicCode] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [mediaNotAvailable, setMediaNotAvailable] = useState(false);
  const [selectedChapterCode, setSelectedChapterCode] = useState('');
  const [isAddContentModalOpen, setIsAddContentModalOpen] = useState(false);
  const [contentTypeDropdownVisible, setContentTypeDropdownVisible] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState('');
  const [selectedContentSLNo, setSelectedContentSLNo] = useState('');
  const [newContentName, setNewContentName] = useState('');
  const [newContentUrl, setNewContentUrl] = useState('');
  const [studyContent, setStudyContent] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [updatedContent, setUpdatedContent] = useState({});
  const [contentData, setContentData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setBreadcrumb(sessionStorage.getItem('breadcrumb') || 'LMS');
  }, []);

  // Chapter Show Call
  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const suCode = userData?.SUCode;
    const fetchChapters = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/chapter-details`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ suCode, boardCode, classCode, subjectCode, chapterCode: selectedChapterCode }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch chapter details');
        }
        const data = await response.json();
        const uniqueChapters = data.filter((chapter, index, self) =>
          index === self.findIndex((t) => t.ChapterName === chapter.ChapterName)
        );
        const initializedChapters = uniqueChapters.map((chapter) => ({
          ...chapter,
          Topics: chapter.Topics, // || []
        }));

        setChapters(initializedChapters);
        // console.log('Chapters Show', initializedChapters);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (suCode && boardCode && classCode && subjectCode) {
      fetchChapters();
    } else {
      setError('One or more parameters are missing.');
      setLoading(false);
    }
  }, [suCode, boardCode, classCode, subjectCode]); //, selectedChapterCode

  // Not showing on first click but at second click.
  const toggleChapter = async (chapterCode) => {
    setExpandedChapters((prev) => ({
      [chapterCode]: !prev[chapterCode] ? true : false, // Only one chapter remains open
    }));

    const userData = JSON.parse(sessionStorage.getItem("user"));
    const suCode = userData?.SUCode;

    if (!expandedChapters[chapterCode]) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/topic-details`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ suCode, boardCode, classCode, subjectCode, chapterCode }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch topic details");
        }

        const topicsData = await response.json();

        setChapters((prevChapters) =>
          prevChapters.map((chapter) =>
            chapter.ChapterCode === chapterCode ? { ...chapter, Topics: topicsData } : chapter
          )
        );

        setSelectedChapterCode(chapterCode);
      } catch (error) {
        setError(error.message);
      }
    }
  };



  const handleAddChapter = async () => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const suCode = userData?.SUCode;
    try {
      const response = await fetch(`${API_BASE_URL}/api/add-chapter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chapterName: newChapterName,
          suCode,
          boardCode,
          classCode,
          subjectCode,
          createdBy: userEmail,
        }),
      });

      const result = await response.json();
      if (!response.ok) throw new Error(result.message || 'Failed to add chapter');

      setChapters([...chapters, { ChapterName: newChapterName }]);
      setIsAddModalOpen(false);
      setNewChapterName('');
    } catch (error) {
      setError(error.message);
    }
  };


  const handleUpdateChapter = async () => {
    if (!editChapterCode || !editChapterName) {
      setError('Chapter code and name are required');
      return;
    }

    try {
      const requestBody = {
        boardCode,
        classCode,
        subjectCode,
        chapterCode: editChapterCode,
        chapterName: editChapterName
      };

      const response = await fetch(`${API_BASE_URL}/api/update-chapter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update chapter');
      }

      setChapters((prevChapters) =>
        prevChapters.map((chapter) =>
          chapter.ChapterCode === editChapterCode
            ? { ...chapter, ChapterName: editChapterName }
            : chapter
        )
      );

      setIsEditModalOpen(false);
      setError(null);
    } catch (error) {
      setError(error.message);
    }
  };



  const handleEditClick = (chapter) => {
    setEditChapterName(chapter.ChapterName);
    setEditChapterCode(chapter.ChapterCode);
    setIsEditModalOpen(true);
  };

  const handleAddTopicClick = (chapterCode) => {
    setSelectedChapterCode(chapterCode);
    setIsAddTopicModalOpen(true);
  };

  const handleAddTopic = async () => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const suCode = userData?.SUCode;
    if (!newTopicName) {
      alert("Please enter a topic name.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/add-topic`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          suCode,
          boardCode,
          classCode,
          subjectCode,
          chapterCode: selectedChapterCode,
          topicName: newTopicName,
          createdBy: userEmail,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add topic");
      }

      const result = await response.json();
      alert(result.message); // Now correctly displays "Topic added successfully"

      // Ensure Topics array exists before updating it
      setChapters((prevChapters) =>
        prevChapters.map((chapter) =>
          chapter.ChapterCode === selectedChapterCode
            ? {
              ...chapter,
              Topics: Array.isArray(chapter.Topics)
                ? [...chapter.Topics, { TopicName: newTopicName }]
                : [{ TopicName: newTopicName }],
            }
            : chapter
        )
      );

      setIsAddTopicModalOpen(false);
      setNewTopicName("");
    } catch (error) {
      console.error("Error adding topic:", error);
    }
  };


  const handleDeleteTopic = async (topicCode) => {
    if (!window.confirm("Are you sure you want to delete this topic?")) {
      return;
    }

    const requestBody = {
      boardCode,
      classCode,
      subjectCode,
      chapterCode: selectedChapterCode,
      topicCode,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/delete-topic`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Server returned an invalid response.");
      }

      const result = await response.json();

      if (result.success) {
        alert(result.message);

        // Remove the topic from UI
        setChapters((prevChapters) =>
          prevChapters.map((chapter) =>
            chapter.ChapterCode === selectedChapterCode
              ? {
                ...chapter,
                Topics: chapter.Topics.filter((t) => t.TopicCode !== topicCode),
              }
              : chapter
          )
        );
      } else {
        alert("Failed to delete topic: " + result.message);
      }
    } catch (error) {
      console.error("Error deleting topic:", error);
      alert("An error occurred while deleting the topic.");
    }
  };




  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditChapterName('');
    setEditChapterCode('');
  };

  // For Topic name-change
  const handleEditTopicClick = () => {
    setIsEditing(true);
  };

  const handleUpdateTopicClick = async () => {
    const requestBody = {
      BoardCode: boardCode,
      ClassCode: classCode,
      SubjectCode: subjectCode,
      ChapterCode: selectedTopic.ChapterCode,
      TopicCode: selectedTopic.TopicCode,
      TopicName: selectedTopic.TopicName,
      UpdatedBy: userEmail,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-topic`, requestBody);

      if (response.status === 200) {
        setIsEditing(false);
        alert('Topic updated successfully!');
      } else {
        alert('Failed to update topic.');
      }
    } catch (error) {
      console.error('Error updating topic:', error);
      alert('Failed to update topic.');
    }
  };

  const handleEditTopic = (topic) => {
    setSelectedTopic(topic);
  };





  const handleAddContentButtonClick = (selectedChapterCode, selectedtopicCode) => {
    setSelectedChapterCode(selectedChapterCode);
    setSelectedTopicCode(selectedtopicCode);
    setIsAddContentModalOpen(true);
  };


  const handleContentTypeClick = () => {
    setContentTypeDropdownVisible(!contentTypeDropdownVisible);
  };

  const handleContentTypeSelect = (contentType) => {
    setSelectedContentType(contentType);
    setContentTypeDropdownVisible(false);
  };


  useEffect(() => {
    if (boardCode && classCode && subjectCode && selectedTopicCode) {
      fetchStudyContent();
    }
  }, [boardCode, classCode, subjectCode, selectedTopicCode, selectedTopic?.ChapterCode]);

  const fetchStudyContent = async () => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const suCode = userData?.SUCode;
    const chapterCode = selectedTopic?.ChapterCode || selectedChapterCode;
    const topicCode = selectedTopic?.TopicCode || selectedTopicCode;

    if (!chapterCode || !topicCode) {
      console.error("ChapterCode or TopicCode is missing");
      return;
    }

    const requestBody = {
      suCode,
      BoardCode: boardCode,
      ClassCode: classCode,
      SubjectCode: subjectCode,
      ChapterCode: chapterCode,
      TopicCode: topicCode,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/studycontent`, requestBody);

      if (response.status === 200) {
        setStudyContent(response.data || []);
        setContentData(response.data);
      } else {
        setStudyContent([]);
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error fetching study content:", error);
      setStudyContent([]); // Reset state on error
    }
  };

  const handleAddContent = async () => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const suCode = userData?.SUCode;
    if (!selectedContentType || !newContentName || !newContentUrl) {
      alert("Please fill all fields before saving.");
      return;
    }

    const requestBody = {
      suCode,
      boardCode,
      classCode,
      subjectCode,
      chapterCode: selectedChapterCode,
      topicCode: selectedTopic.TopicCode,
      contentType: selectedContentType,
      contentName: newContentName,
      contentUrl: newContentUrl,
      createdBy: userEmail
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/api/insert-content`, requestBody);

      if (response.data.respCode === '0') {
        alert('Content added successfully');
        fetchStudyContent();
        setSelectedContentType('');
        setNewContentName('');
        setNewContentUrl('');
        setIsAddContentModalOpen(false);
      } else {
        alert(`Failed to add content: ${response.data.respMsg}`);
      }
    } catch (error) {
      console.error('Error inserting content:', error);
      alert('Error adding content. Please try again.');
    }
  };


  // Start editing a row
  const handleContentEdit = (index, item) => {
    setEditingRow(index);
    setUpdatedContent(prev => ({
      ...prev,
      [index]: { ContentName: item.ContentName, ContentURL: item.ContentURL }
    }));
  };


  const handleDeleteContent = async (content) => {
    if (!window.confirm("Are you sure you want to delete this content?")) {
      return;
    }

    const requestBody = {
      boardCode,
      classCode,
      subjectCode,
      chapterCode: selectedChapterCode,
      topicCode: selectedTopic.TopicCode,
      contentType: content.ContentType,
      contentName: content.ContentName,
      contentURL: content.ContentURL,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/delete-studycontent`, {
        method: "POST", // Changed from DELETE to POST
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (result.success) {
        alert(result.message);

        // Remove the content from UI
        setStudyContent((prevContent) =>
          prevContent.filter((item) => item.ContentName !== content.ContentName)
        );
      } else {
        alert("Failed to delete content: " + result.message);
      }
    } catch (error) {
      console.error("Error deleting content:", error);
      alert("An error occurred while deleting the content.");
    }
  };



  const handleContentUpdate = async (index, content) => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    const suCode = userData?.SUCode;
    const updatedOn = new Date().toISOString();
    const updatedBy = userEmail;
    const updatedData = {
      suCode,
      boardCode,
      classCode,
      subjectCode,
      chapterCode: selectedChapterCode,
      topicCode: selectedTopic.TopicCode || selectedTopicCode,
      contentSLNo: content.ContentSLNo,
      contentType: content.ContentType,
      contentName: updatedContent[index]?.ContentName || content.ContentName,
      contentURL: updatedContent[index]?.ContentURL || content.ContentURL,
      updatedBy,
      updatedOn,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/update-studycontent`, {
        method: "POST", // Changed from PUT to POST
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedData),
      });

      const result = await response.json();

      if (result.success) {
        alert("Content updated successfully!");
        setEditingRow(null);
        fetchStudyContent();
        setStudyContent((prevContent) =>
          prevContent.filter((item) => item.ContentName !== content.ContentName)
        );
      } else {
        alert("Failed to update content: " + result.message);
      }
    } catch (error) {
      console.error("Error updating content:", error);
      alert("An error occurred while updating content.");
    }
  };

  useEffect(() => {
    const fetchContentButtons = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/get-content-buttons`, {
          boardCode,
          classCode,
          subjectCode,
          chapterCode: selectedTopic?.ChapterCode,
          topicCode: selectedTopicCode,
        });
        setContentData(response.data);
      } catch (error) {
        console.error("Error fetching content buttons:", error);
      }
    };

    if (boardCode && classCode && subjectCode && selectedTopic?.ChapterCode && selectedTopicCode) {
      fetchContentButtons();
    }
  }, [boardCode, classCode, subjectCode, selectedTopic?.ChapterCode, selectedTopicCode]);






  const handleTopicClick = async (topicCode, chapterCode) => {
    if (selectedTopicCode === topicCode) {
      setSelectedTopicCode(null);
      // setPptUrl('');
      setMediaUrl('');
      setSelectedMedia('');
      setMediaNotAvailable(false);
    } else {
      setSelectedChapterCode(chapterCode); // Set the selected chapter code
      setSelectedTopicCode(topicCode); // Set the selected topic code
      fetchContentButtons(topicCode, chapterCode);
    }
  };

  const fetchContentButtons = async (topicCode, chapterCode) => {
    try {
      // console.log('I am at line 726');
      const response = await axios.post(`${API_BASE_URL}/api/get-content-buttons`, {
        boardCode,
        classCode,
        subjectCode,
        chapterCode,
        topicCode,
      });
      // console.log('I am at FecthContentButtons', response.data);
      setContentData(response.data);
    } catch (error) {
      console.error("Error fetching content buttons:", error);
    }
  };



  const handleMediaSelection = async (contentType, contentSLNo) => {
    try {
      const userData = JSON.parse(sessionStorage.getItem('user'));
      const suCode = userData?.SUCode;
  
      const response = await axios.post(`${API_BASE_URL}/api/get-media`, {
        suCode,
        boardCode,
        classCode,
        subjectCode,
        chapterCode: selectedChapterCode,
        topicCode: selectedTopicCode,
        contentSLNo
      });
  
      if (response.data) {
        const { popupUrl, newTabUrl, excelUrl } = response.data;
        setMediaUrl(popupUrl || newTabUrl || excelUrl || '');
        setSelectedMedia(contentType);
  
        if (popupUrl) {
          setIsModalOpen(true);
        } else if (newTabUrl) {
          window.open(newTabUrl, '_blank');
        } else if (excelUrl) {
          window.location.href = excelUrl;
        }
      } else {
        setMediaUrl('');
      }
  
      setSelectedMedia(contentType);
      setSelectedContentSLNo(contentSLNo);
    } catch (error) {
      console.error('Error fetching media:', error);
    }
  };
  


  const closeModal = () => {
    setIsModalOpen(false);
    setMediaUrl('');
  };



  const buttonIcons = {
    PPT: <i className="bi bi-file-earmark-richtext"></i>,
    Video: <i className="bi bi-play-circle"></i>,
    ASSIGNMENT: <i className="bi bi-pencil-square"></i>,
    'Activity Video': <i className="bi bi-film"></i>,
    'Activity Item': <i className="bi bi-list-check"></i>,
    'Simulation Video': <i className="bi bi-graph-up"></i>,
    WORKSHEET: <i className="bi bi-file-text"></i>
  };



  return (
    <AdminHome>
      <div className="chapter-details">
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        <div className="breadcrumb" style={{ display: 'none' }}>
          <p style={{ display: 'none' }}>{breadcrumb}</p>
        </div>
        <div className="Two-Content">

          <button onClick={() => navigate(-1)} className="back-button">Back</button>

          <div class="Chapter-Add-button-container" onClick={() => setIsAddModalOpen(true)}><button type="button" class="Chapter-Add-button">
            <span class="button__text">Add Chapter</span>
            <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke-width="2" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" height="24" fill="none" class="svg"><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span>
          </button></div>
        </div>

        <div className='list-Container'>
          <ul>
            {chapters.map((chapter) => (
              <li key={chapter.ChapterCode}>
                <div className='Chapter-list'
                  onClick={() => toggleChapter(chapter.ChapterCode)}>
                  <span className="chapter-slno"
                    style={{ marginRight: '10px', color: 'white' }}>
                    {chapter.ChapterSlNo}.
                  </span>
                  <span>
                    <span style={{ marginRight: '5px' }}>
                      {expandedChapters[chapter.ChapterCode] ? '▼' : '▲'}
                    </span>
                    {chapter.ChapterName}
                    <span className='Edit-icon' style={{ color: 'blue', marginLeft: '20px' }}>
                      <button class="Chapter-edit-button" onClick={() => handleEditClick(chapter)}>
                        <svg class="Chapter-edit-svgIcon" viewBox="0 0 512 512">
                          <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                        </svg>
                      </button>
                    </span>

                  </span>

                  <span style={{ float: 'right', display: 'flex' }}>

                    {chapter.Topics && chapter.Topics > 0 && `${chapter.Topics} topics`}
                    {expandedChapters[chapter.ChapterCode]
                      ? `${chapter.Topics.length} topics`
                      : chapter.Topics && chapter.Topics.length > 0 && `${chapter.Topics.length} topics`}
                    <button className="Btn" onClick={() => handleAddTopicClick(chapter.ChapterCode)}>
                      <div className="sign">+</div>
                      <div className="text">Add Topic</div>
                    </button>

                  </span>

                </div>
                {expandedChapters[chapter.ChapterCode] && (
                  <ul className="expanded-content">
                    {/* Error at one Click */}
                    {chapter.Topics && chapter.Topics.length > 0 ? (
                      chapter.Topics.map((topic) => {
                        return (
                          <li key={topic.TopicCode}>
                            <div
                              onClick={() => handleTopicClick(topic.TopicCode, chapter.ChapterCode)}
                              className={selectedTopicCode === topic.TopicCode ? 'expanded-topic' : ''}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <span className="topic-slno" style={{ marginRight: '10px', color: 'black' }}>
                                {chapter.ChapterSlNo}.{topic.TopicSLNo}.
                              </span>
                              <span style={{ display: 'flex', justifyContent: 'flex-start' }}>{selectedTopicCode === topic.TopicCode ? '[-]' : '[+]'} {topic.TopicName}
                                <span className='edit-icon-topic' style={{ display: 'flex', color: 'blue', marginLeft: '10px' }}>
                                  <button class="edit-button" onClick={() => handleEditTopic(topic)}>
                                    <svg class="edit-svgIcon" viewBox="0 0 512 512">
                                      <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                    </svg>
                                  </button>
                                </span>
                              </span>
                              <button className="remove-button" onClick={() => handleDeleteTopic(topic.TopicCode)}>
                                <img
                                  src="https://cdn-icons-png.flaticon.com/128/6861/6861362.png"
                                  alt="remove"
                                />
                              </button>

                            </div>

                            {selectedTopicCode === topic.TopicCode && (
                              <div style={{ padding: '10px', borderRadius: '5px' }}>
                                <div className="Three-button">
                                  {contentData.map((item) => (
                                    <button
                                      key={item.ContentSLNo}
                                      onClick={() => handleMediaSelection(item.ContentType, item.ContentSLNo)}
                                      className={`content-button ${item.ContentType.toLowerCase().replace(/\s+/g, '-')}-button`}
                                    >
                                      {buttonIcons[item.ContentType] || <i className="bi bi-file"></i>}
                                      {item.ContentType} : {item.ContentName}
                                    </button>
                                  ))}
                                </div>

                                {/* Conditional Rendering for Selected Media */}
                                {isModalOpen && mediaUrl && (
                                  <div className="video-modal">
                                    <div className="modal-content">
                                      <span className="close-video" onClick={closeModal}>&times;</span>
                                      {selectedMedia === 'Video' ? (
                                        <iframe
                                          width="100%"
                                          height="500"
                                          src={mediaUrl}
                                          title="Media Player"
                                          frameBorder="0"
                                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                        ></iframe>
                                      ) : selectedMedia === 'PDF' ? (
                                        <iframe
                                          width="100%"
                                          height="500"
                                          src={mediaUrl}
                                          title="PDF Viewer"
                                          frameBorder="0"
                                        ></iframe>
                                      ) : null}
                                    </div>
                                  </div>
                                )}

                                {selectedMedia && selectedContentSLNo && mediaNotAvailable && (
                                  <p>
                                    <strong>Content Type:</strong> {selectedMedia} <br />
                                    <strong>Content SL No:</strong> {selectedContentSLNo} <br />
                                    {mediaUrl ? (
                                      <>
                                        <strong>Media URL:</strong>
                                        <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
                                          {mediaUrl}
                                        </a>
                                      </>
                                    ) : (
                                      <strong>No URL Available</strong>
                                    )}
                                  </p>
                                )}


                              </div>
                            )}
                          </li>
                        );
                      })
                      // <p>Test At Topic</p>
                    ) : (
                      <p>No topics available for this chapter test.</p>
                    )}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>


        {/* Add Chapter Modal */}
        {isAddModalOpen && (
          <div className="Chapter-Add-modal">

            <h3 className='Chapter-Add-heading'>Add Chapter</h3>
            <input
              type="text"
              placeholder="Enter Chapter Name"
              value={newChapterName}
              onChange={(e) => setNewChapterName(e.target.value)}
              className="Chapter-Add-modal-input"
            />
            <div className="modal-footer">
              <button type="button" class="close" onClick={() => setIsAddModalOpen(false)}>&times;</button>
              <button onClick={handleAddChapter} type="submit" className="Submit-button">Submit</button>
            </div>
          </div>
        )}

        {/* Edit Chapter Modal */}
        {isEditModalOpen && (
          <div className="Chapter-edit-modal">
            <h3 className='Chapter-Add-heading'>Edit : {editChapterName} ({editChapterCode})</h3>
            <label htmlFor="" className='Chapter-edit-modal-label'>Change Chapter Name :</label>
            <input
              type="text"
              value={editChapterName}
              onChange={(e) => setEditChapterName(e.target.value)}
              className="Topic-edit-modal-input"
            />
            <div className="modal-footer">
              <button type="button" class="close" onClick={() => closeEditModal(false)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <button type="submit" className="Submit-button" onClick={handleUpdateChapter}>Update
              </button>
            </div>
          </div>
        )}

        {/* Add Topic Modal */}
        {isAddTopicModalOpen && (
          <div className="edit-modal">
            <h3 className='Chapter-Add-heading'>Add New Topic</h3>
            <input
              type="text"
              value={newTopicName}
              className="Topic-edit-modal-input"
              onChange={(e) => setNewTopicName(e.target.value)}
              placeholder="Enter Topic Name"
            />
            <div className="modal-footer">
              <button onClick={() => setIsAddTopicModalOpen(false)} type="button" class="close">&times;</button>
              <button onClick={handleAddTopic} type="submit" className="Submit-button">Add Topic</button>
            </div>
          </div>
        )}

        {selectedTopic && (
          <div className="Topic-edit-details">
            <div className="Topic-edit-modal">
              <h4 className='Topic-edit-modal-heading'>Edit :- {selectedTopic.ChapterName} ({selectedTopic.ChapterCode}) & {selectedTopic.TopicName} ({selectedTopic.TopicCode})</h4>

              <div className="Topic-Name-Change">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Topic Name:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic?.TopicName}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, TopicName: e.target.value })}
                    disabled={!isEditing}
                  />
                  {!isEditing && (
                    <div className="Topic-Edit-Button-Container">
                    <button className="btn btn-warning btn-sm mx-2 p-1 py-0" type="button" onClick={handleEditTopicClick}>
                      <i className="bi bi-pencil"></i>
                    </button>
                    </div>
                  )}
                  {isEditing && (
                    <div className="Topic-Edit-Button-Container">
                    <button type='button' className='Topic-Submit-button' onClick={handleUpdateTopicClick}
                      style={{ display: 'flex', marginLeft: '20px' }}>Update</button>
                      </div>
                  )}
                </div>
              </div>


              <table className="content-table">
                <thead>
                  <tr>
                    <th>Content Type</th>
                    <th>Content Name</th>
                    <th>Content URL</th>
                    <th>Remove</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {studyContent.length > 0 ? (
                    studyContent.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input type="text" value={item.ContentType} className="Topic-edit-modal-input" readOnly />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={editingRow === index ? updatedContent[index]?.ContentName : item.ContentName}
                            className="Topic-edit-modal-input"
                            readOnly={editingRow !== index}
                            onChange={(e) => setUpdatedContent(prev => ({
                              ...prev,
                              [index]: { ...prev[index], ContentName: e.target.value }
                            }))}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={editingRow === index ? updatedContent[index]?.ContentURL : item.ContentURL}
                            className="Topic-edit-modal-input"
                            readOnly={editingRow !== index}
                            onChange={(e) => setUpdatedContent(prev => ({
                              ...prev,
                              [index]: { ...prev[index], ContentURL: e.target.value }
                            }))}
                          />
                        </td>
                        <td>
                          <button className="remove-button" onClick={() => handleDeleteContent(item)}>
                            <img src="https://cdn-icons-png.flaticon.com/128/6861/6861362.png" alt="remove" />
                          </button>
                        </td>
                        <td>
                          {editingRow === index ? (
                            <button className="Topic-Submit-button" onClick={() => handleContentUpdate(index, item)}>Update</button>
                          ) : (
                            <button className="btn btn-warning btn-sm" onClick={() => handleContentEdit(index, item)} style={{ fontSize: '12px', padding: '4px', height: '20px' }}>
                              <i className="bi bi-pencil" style={{ color: 'blue' }}></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No study content found.</td>
                    </tr>
                  )}
                </tbody>
              </table>



              <div className="Topic-edit-modal-footer">
                <button type="button" class="close" onClick={() => setSelectedTopic(null)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>

                <div class="Content-Add-button-container" ><button type="button" class="Content-Add-button" onClick={() => handleAddContentButtonClick(selectedChapterCode, selectedTopicCode)}>
                  <span class="button__text">Add Content</span>
                  <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke-width="2" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" height="24" fill="none" class="svg"><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span>
                </button>
                </div>
              </div>

            </div>
          </div>
        )}

        {isAddContentModalOpen && (
          <div className="Content-modal-overlay">
            <div className="Content-modal-content">
              <h2 className="Content-modal-heading">Add Content</h2>

              {/* Content Type Field */}
              <div className="Content-modal-input-container">
                <label htmlFor="Content Type" className="Content-modal-input-container-label">Content Type</label>
                <input
                  type="text"
                  value={selectedContentType}
                  onClick={handleContentTypeClick}
                  readOnly
                  className="Topic-edit-modal-input"
                  placeholder="Select Content Type"
                />
                {contentTypeDropdownVisible && (
                  <div className="dropdown">
                    <ul>
                      {['PPT', 'Video', 'Assignment', 'Worksheet', 'Activity Video', 'Activity Item', 'Simulation Video'].map((contentType, index) => (
                        <li key={index} onClick={() => handleContentTypeSelect(contentType)}>
                          {contentType}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Content Name Field */}
              <div className="Content-modal-input-container">
                <label htmlFor="Content Name" className="Content-modal-input-container-label">Content Name</label>
                <input
                  type="text"
                  value={newContentName}
                  onChange={(e) => setNewContentName(e.target.value)}
                  className="Topic-edit-modal-input"
                  placeholder="Content Name"
                />
              </div>

              {/* Content URL Field */}
              <div className="Content-modal-input-container">
                <label htmlFor="Content URL" className="Content-modal-input-container-label">Content URL</label>
                <input
                  type="text"
                  value={newContentUrl}
                  onChange={(e) => setNewContentUrl(e.target.value)}
                  className="Topic-edit-modal-input"
                  placeholder="Content URL"
                />
              </div>

              {/* Buttons */}
              <div className="Topic-edit-modal-footer">
                <button type="button" className="close-button" onClick={() => setIsAddContentModalOpen(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" className="Topic-Submit-button" onClick={handleAddContent}>
                  <span className="button__text">Save</span>
                </button>
              </div>
            </div>
          </div>
        )}

      </div>

    </AdminHome>
  );
};

export default ChapterDetails;
