import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FunsholarLogo01 from '../../Images/FunsholarLogo01.png';
import '../../../ALL Css/LoginTypesCSS/RestorePassword.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RestorePassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']); 
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState(1);
    const [generatedOtp, setGeneratedOtp] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [timer, setTimer] = useState(120); 
    const [isOtpSent, setIsOtpSent] = useState(false); 

    const [step1Error, setStep1Error] = useState('');
    const [step2Error, setStep2Error] = useState('');
    const [step3Error, setStep3Error] = useState('');

    const navigate = useNavigate();


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    useEffect(() => {
        let countdown;
        if (isOtpSent && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }

        if (timer === 0) {
            clearInterval(countdown);
        }

        return () => clearInterval(countdown);
    }, [isOtpSent, timer]);

    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        
        if (!email) {
          setStep1Error('Email is required.');
          return;
        }
      
        if (!/\S+@\S+\.\S+/.test(email)) {
          setStep1Error('Please enter a valid email address.');
          return;
        }
      
        try {
          const response = await fetch(`${API_BASE_URL}/send-otp`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
          });
      
          const data = await response.json();
      
          if (response.ok && data.success) {
            setGeneratedOtp(data.otp);
            setIsOtpSent(true);
            setTimer(120);
            setStep(2);
            setStep1Error('');
          } else {
            setStep1Error(data.message || 'Failed to send OTP. Please try again.');
          }
        } catch (error) {
          console.error('Error sending OTP:', error);
          setStep1Error('An unexpected error occurred. Please try again later.');
        }
      };
      

    const handleResendOtp = async (e) => {
        e.preventDefault();
        setOtp(['', '', '', '']);
        setTimer(120);
        setIsOtpSent(false); 
        document.getElementById('otp-input-0').focus();
        handleSubmitEmail(e); 
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus();
            }
        }
    };

    const handleOtpFocus = (index) => {
        document.getElementById(`otp-input-${index}`).focus();
    };

    const handleSubmitOtp = async (e) => {
        e.preventDefault();
        const otpString = otp.join('');
        if (generatedOtp && otpString === generatedOtp) {
            setStep(3); 
            setStep2Error(''); 
        } else {
            setStep2Error('Incorrect OTP. Please try again.');
        }
    };

    const handleSubmitPassword = async (e) => {
        e.preventDefault();
        if (newPassword === confirmPassword) {
            try {
                const response = await fetch(`${API_BASE_URL}/reset-password`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, newPassword }),
                });
    
                const data = await response.json();
    
                if (data.success) {
                    alert('Password successfully reset! Redirecting to login...');
                    navigate('/funscholaradmin');
                    setEmail('');
                    setNewPassword('');
                    setConfirmPassword('');
                    setStep3Error('');
                } else {
                    setStep3Error(data.message || 'Failed to reset password. Please try again.');
                }
            } catch (error) {
                console.error('Error:', error);
                setStep3Error('An error occurred while resetting the password.');
            }
        } else {
            setStep3Error('Passwords do not match.');
        }
    };
    

    const confirmPasswordStyle = newPassword === confirmPassword ? { borderColor: 'green' } : { borderColor: 'red' };

    return (
        <div className="Total-Content">
            <div className="background">
                <div className="Logo">
                    <img src={FunsholarLogo01} alt="img" />
                </div>
            </div>

            <div className="login-Container">
                {step1Error && step === 1 && <div className="error-message">{step1Error}</div>}
                {step2Error && step === 2 && <div className="error-message">{step2Error}</div>}
                {step3Error && step === 3 && <div className="error-message">{step3Error}</div>}

                {step === 1 && (
                    <>
                        <div className="input-Container1">
                            <label className="input-label">Enter your Email</label>
                            <input
                                id="email"
                                placeholder="Email"
                                type="email"
                                className="input-field"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="Login-button-container">
                            <button className="login-button" onClick={(e) => handleSubmitEmail(e)}>
                                <span>Get OTP</span>
                            </button>
                        </div>
                    </>
                )}

                {step === 2 && (
                    <>
                        <div className="input-Container1">
                            <label className="input-label">Enter OTP</label>
                            <div className="inputs">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`otp-input-${index}`}
                                        type="tel"
                                        maxLength="1"
                                        placeholder=""
                                        value={digit}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)} 
                                        autoFocus={index === 0}
                                        onFocus={() => handleOtpFocus(index)} 
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="otp-time-container">
                            {timer > 0 ? (
                                <p>OTP will expire in: {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')} minutes</p>
                            ) : (
                                <p className="resend">You didn't receive the code? <p className="resend-action" onClick={(e) => handleResendOtp(e)}>Resend OTP</p></p>
                            )}
                        </div>
                        <div className="Login-button-container">
                            <button className="login-button" onClick={handleSubmitOtp}>
                                <span>Submit OTP</span>
                            </button>
                        </div>
                    </>
                )}

                {step === 3 && (
                    <>
                        <div className="input-Container1">
                            <label className="input-label">New Password</label>
                            <input
                                id="newPassword"
                                placeholder="New Password"
                                type={passwordVisible ? 'text' : 'password'}
                                className="input-field"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <div className="password-icon" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}><svg viewBox="0 0 576 512" height="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
                                </svg></div>
                        </div>

                        <div className="input-Container2">
                            <label className="input-label">Confirm Password</label>
                            <input
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                className="input-field"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                style={confirmPasswordStyle}
                            />
                            <div className="password-icon" onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer' }}><svg viewBox="0 0 576 512" height="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
                                </svg></div>
                        </div>

                        <div className="Login-button-container">
                            <button className="login-button" onClick={handleSubmitPassword}>
                                <span>Submit</span>
                            </button>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default RestorePassword;
