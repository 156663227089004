import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';
import FunsholarLogo01 from '../Images/FunsholarLogo01.png';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import '../../ALL Css/ALL Dashboard CSS/AdminHome.css';
import Dashboard from './AdminDetails/Dashboard';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminHome = ({ children }) => {
  const [menuData, setMenuData] = useState([]);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { EmailID, Password } = useParams();
  const profilePopupRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [profileData, setProfileData] = useState({});
  const [photo, setPhoto] = useState(null);




  useEffect(() => {
    const storedBreadcrumb = sessionStorage.getItem('breadcrumb');
    if (storedBreadcrumb) {
      setBreadcrumb(storedBreadcrumb);
    } else {
      setBreadcrumb('');
    }
  }, [location.pathname]);


  const iconMapping = {
    "User Management": "bi bi-person",
    "Student Enrollment": "bi bi-file-earmark-person",
    "Batch Management": "bi bi-calendar",
    "Teacher": "bi bi-person-check",
    "LMS`": "bi bi-book",
    "Exam Management Portal": "bi bi-file-earmark-text",
    "Inventory Management": "bi bi-box",
    "Demo Class Management System": "bi bi-play-circle",
    "Enquiry": "bi bi-info-circle",
    "Doubt Solving Classes Credit System": "bi bi-question-circle",
  };
  

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        let storedUser = sessionStorage.getItem('user');
        if (!storedUser && EmailID && Password) {
          const response = await axios.post(`${API_BASE_URL}/login`, {
            email: EmailID,
            password: Password,
          });

          if (response.data.ResCode === 1) {
            storedUser = JSON.stringify(response.data);
            sessionStorage.setItem('user', storedUser);
          } else {
            setError('Invalid credentials. Please log in again.');
            navigate('/login');
            return;
          }
        }

        if (storedUser) {
          const user = JSON.parse(storedUser);
          setUserDetails(user);
          await fetchMenuData(user.SUCode, user.EmailID);
        }
      } catch (err) {
        setError('Error fetching user details: ' + err.message);
      }
    };

    fetchUserDetails();
  }, [EmailID, Password, navigate]);

  const fetchMenuData = async (SUCode, EmailID) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/menus`, { SUCode, EmailID });
      setMenuData(response.data);
    } catch (error) {
      setError('Error fetching menu: ' + error.message);
    } finally {
      setLoading(false);
    }
  };


  const toggleSubmenu = (menu) => {
    if (!isSidebarVisible) {
      setIsSidebarVisible(true);
    }
  
    const submenus = menuData.filter((item) => item.ParentCode === menu.MenuCode);
  
    if (submenus.length === 0) {
      const fullBreadcrumb = menu.MenuName;
      setBreadcrumb(fullBreadcrumb);
      sessionStorage.setItem('breadcrumb', fullBreadcrumb);
      const loadComponent = async () => {
        try {
          const Component = await import(`../../Components/AdminPart/AdminDetails/${menu.FrmName}.js`);
          setLoadedComponent(() => lazy(() => Promise.resolve({ default: Component.default })));
        } catch (error) {
          console.error(`Component not found for FrmName: ${menu.FrmName}`, error);
          setLoadedComponent(() => NotFound);
        }
      };
  
      loadComponent();
    } else {
      setExpandedMenu((prev) => (prev === menu.MenuCode ? null : menu.MenuCode));
    }
  
    setActiveMenu(menu.MenuCode);
  };

  const NotFound = () => <div>404 Page Not Found</div>; 

  const handleSubmenuClick = (submenu, parentMenuName) => {
    const fullBreadcrumb = `${parentMenuName} > ${submenu.MenuName}`;
    setBreadcrumb(fullBreadcrumb);
    sessionStorage.setItem('breadcrumb', fullBreadcrumb);
    const loadComponent = async () => {
      try {
        const Component = await import(`../../Components/AdminPart/AdminDetails/${submenu.FrmName}.js`);
        setLoadedComponent(() => lazy(() => Promise.resolve({ default: Component.default })));
      } catch (error) {
        console.error(`Component not found for FrmName: ${submenu.FrmName}`, error);
        setLoadedComponent(() => NotFound); 
      }
    };
  
    loadComponent();
  };

  // Clear breadcrumb when navigating back from submenu to menu
  useEffect(() => {
    if (location.pathname === '/adminhome') {
      sessionStorage.removeItem('breadcrumb');
      setBreadcrumb('');
    }
  }, [location.pathname]);

  const renderMenuItems = () => {
    // const level1Menus = menuData.filter((item) => item.MenuLevel === 'Level1');
    const level1Menus = menuData.filter((item) => item.ParentCode === 0);
    return level1Menus.map((menu) => {
      const hasSubmenus = menuData.some((subMenu) => subMenu.ParentCode === menu.MenuCode);
  
      return (
        <div key={menu.MenuCode}>
          <div
            className="Menu-Item"
            onClick={() => toggleSubmenu(menu)}
            style={{
              color: '#2c384af2',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#FB8500';
              e.currentTarget.style.borderRadius = '5px';
              e.currentTarget.style.color = 'white';
              e.currentTarget.style.padding = '5px';
              const icon = e.currentTarget.querySelector('i');
              if (icon) {
                icon.style.color = 'white';
              }
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '';
              e.currentTarget.style.color = '#2c384af2';
              const icon = e.currentTarget.querySelector('i');
              if (icon) {
                icon.style.color = '';
              }
            }}
          >
            {isSidebarVisible && (
              <>
                <i
                  className={iconMapping[menu.MenuName]}
                  style={{
                    marginRight: '15px',
                    fontWeight: 'bold',
                    fontSize: isSidebarVisible ? '20px' : '30px',
                  }}
                ></i>
                {menu.MenuName}
              </>
            )}
            {hasSubmenus && (
              <span
                style={{
                  marginLeft: 'auto',
                  transition: 'transform 0.3s',
                  transform: expandedMenu === menu.MenuCode ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              >
                <i className="bi bi-chevron-down"></i>
              </span>
            )}
          </div>
          {expandedMenu === menu.MenuCode && renderSubmenuItems(menu.MenuCode, menu.MenuName)}
        </div>
      );
    });
  };
  



  const renderSubmenuItems = (parentMenuCode, parentMenuName) => {
    // const level2Submenus = menuData.filter((submenu) => submenu.ParentCode === parentMenuCode && submenu.MenuLevel === 'Level2');
    const level2Submenus = menuData.filter((submenu) => submenu.ParentCode === parentMenuCode);
    if (level2Submenus.length === 0) return null;

    return (
      <div className="submenu">
        {level2Submenus.map((submenu) => (
          <div key={submenu.MenuCode} className="submenu-item">
            <div
              onClick={() => handleSubmenuClick(submenu, parentMenuName)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              {submenu.MenuName}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const togglePopup = () => {
    setIsProfilePopupVisible((prev) => !prev);
    if (!isProfilePopupVisible) {
      const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
      if (storedUserDetails) {
        setUserDetails(storedUserDetails);
      }
    }
  };

  useEffect(() => {
    const UserDetails = JSON.parse(sessionStorage.getItem("user"));
    if (UserDetails?.EmailID) {
      axios
        .post(`${API_BASE_URL}/api/profile`, { EmailID: UserDetails.EmailID })
        .then((response) => {
          const { UserName, EmailID, Mobile, WhatsApp, Active, UserImage } = response.data;
          setProfileData({
            name: UserName,
            email: EmailID,
            mobile: Mobile,
            whatsapp: WhatsApp,
            active: Active,
          });
          if (UserImage) {
            setPhoto(`data:image/jpeg;base64,${UserImage}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data", error);
        });
    } else {
      console.error("No email ID found in session storage");
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setUserDetails(null);
    navigate('/loginselect');
  };

  const handleLogoClick = () => {
    setLoadedComponent(() => null);
    setTimeout(() => {
      setLoadedComponent(() => Dashboard);
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profilePopupRef.current && !profilePopupRef.current.contains(event.target)) {
        setIsProfilePopupVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;



  return (
    <div className="Container">
      <nav className="Navbar">
        <div className="Adjust-Sidebar" onClick={toggleSidebar}>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="MenuIcon"
          >
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
          </svg>
        </div>

        <div className="Funscholar-Logo" style={{ cursor: 'pointer' }} onClick={handleLogoClick}>
          <img src={FunsholarLogo01} alt="Funscholar Logo" />
        </div>

        <div className="Navbar-Heading">
          {breadcrumb}
        </div>

        <div className="profile-container" onClick={togglePopup}>
          <div className="profile-icon">
            {photo ? (
              <img
                src={photo}
                alt="Profile"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
            ) : (
              <img src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png" alt="Profile-Icon"  style={{ fontSize: "30px", marginRight: "30px", height:'30px' }}/>
            )}
          </div>
        </div>


        {isProfilePopupVisible && userDetails && (
          <div className="profile-popup" ref={profilePopupRef}>
            <p> <div className="profile-icon">
              {photo ? (
                <img
                  src={photo}
                  alt="Profile"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <img src="https://cdn-icons-png.flaticon.com/128/3135/3135715.png" alt="Profile-Icon"  style={{ fontSize: "30px", height:'30px'}}/>
              )}
            </div></p>
            <p> {userDetails.UserName}</p>
            <p><strong>Edit:</strong>
              <label onClick={() => navigate('/profile')} className="Edit-button">
                        <button className="btn btn-warning btn-sm mx-2 p-1 py-0" type="button">
                          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="fs-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path>
                          </svg>
                        </button>
                      </label>
              </p>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}

      </nav>

      <div className="container-fluid">
        <div className="sidebar-container">
          {isSidebarVisible ? (
            <div className="sidebar">{renderMenuItems()}</div>
          ) : (
            <div className="sidebar-collapsed">
              {menuData.map((menu) => (
                <div key={menu.MenuCode} className="collapsed-menu" onClick={() => toggleSubmenu(menu)}>
                  <i className={iconMapping[menu.MenuName]}
                    style={{
                      color: activeMenu === menu.MenuCode ? 'blue' : 'inherit',
                    }}
                  ></i>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="Content">
        {children || (LoadedComponent ? (
      <Suspense fallback={<div>Loading...</div>}>
        <LoadedComponent />
      </Suspense>
    ) : (
      <Dashboard /> 
    ))}
        </div>
      </div>

      
    </div>
  );
};

export default AdminHome;
